<template>
  <v-app>
    <!-- <Header :title='title' :subtitle='subtitle' :showBtn='showBtn'/> -->
    <v-main>
      <router-view />
    </v-main>

    <Footer v-if="showFooter" :color="color" />
  </v-app>
</template>

<script>
// import Header from '@/components/Header/Header.vue';
import Footer from "@/components/Footer/Footer.vue";

export default {
  name: "App",
  components: {
    // Header,
    Footer,
  },

  data: () => ({}),
  computed: {
    title() {
      if (this.$route.name === "OurDev") {
        return this.$t("header.ourDev.title");
      }
      return this.$t("header.main.title");
    },
    subtitle() {
      if (this.$route.name === "OurDev") {
        return this.$t("header.ourDev.subtitle");
      }
      return this.$t("header.main.subtitle");
    },
    color() {
      if (this.$route.name === "OurDev") {
        return "#2AB4FF1A";
      }
      return "#FFF";
    },
    showBtn() {
      if (this.$route.name === "OurDev") {
        return false;
      }
      return true;
    },
    showFooter() {
      if (this.$route.name === "Maintenance") {
        return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss">
@import "@/assets/scss/global.scss";

.v-carousel {
  .mdi-circle::before {
    color: $mainColor;
  }
}
@font-face {
  font-family: "Lato";
  font-weight: 100;
  font-style: normal;
  src: url("./assets/fonts/Lato-Thin.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  src: url("./assets/fonts/Lato-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lato";
  font-weight: 700;
  font-style: normal;
  src: url("./assets/fonts/Lato-Black.ttf") format("truetype");
}
* {
  font-family: "Lato", sans-serif;
  font-style: normal;
}
</style>
