<template>
<!--  eslint-disable max-len  -->
<svg :width="width" :height="height" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path  d="M29.0526 2.72285C27.9722 3.18462 26.821 3.49069 25.6208 3.63931C26.8555 2.921 27.7979 1.79223 28.241 0.431692C27.0898 1.10046 25.8187 1.57285 24.4641 1.83646C23.371 0.702385 21.8131 0 20.1135 0C16.816 0 14.1613 2.60785 14.1613 5.80485C14.1613 6.26485 14.2013 6.70715 14.2993 7.12823C9.34769 6.89292 4.96618 4.58054 2.02279 1.058C1.50892 1.92669 1.2075 2.921 1.2075 3.99138C1.2075 6.00123 2.26974 7.78285 3.85311 8.81431C2.89618 8.79661 1.95742 8.52592 1.16211 8.09954C1.16211 8.11723 1.16211 8.14023 1.16211 8.16323C1.16211 10.9834 3.22666 13.3258 5.934 13.8655C5.44918 13.9946 4.92079 14.0565 4.37242 14.0565C3.99111 14.0565 3.60616 14.0353 3.24482 13.9575C4.01653 16.2557 6.20637 17.9453 8.81021 18.0002C6.78379 19.5447 4.21082 20.4753 1.4254 20.4753C0.936948 20.4753 0.468474 20.4541 0 20.3957C2.63834 22.0535 5.76513 23 9.13705 23C20.0972 23 26.0893 14.1538 26.0893 6.486C26.0893 6.22946 26.0802 5.98177 26.0675 5.73585C27.2496 4.91846 28.2428 3.89762 29.0526 2.72285Z" :fill="iconColor"/>
</svg>

</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
      opacity:{
      type: Number || String,
      default: 1
    }
  }
}
</script>
