<template>
  <v-footer
    app
    absolute
    padless
    :color="color"
    :fixed="false"
  >
    <v-container class="footer_container">
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-space-between"
        >
          <div 
            @click="goTo('Main')"
          >
            <Logo
              height="62.23"
              width="70.25"
              color="#2AB4FF"
            />
          </div>
          <div class="align-self-center">
            <Facebook
              icon-color="#2AB4FF"
              width="15"
              heigth="26"
              class="footer-icon"
            />
            <Telegram
              icon-color="#2AB4FF"
              width="23"
              heigth="27"
              class="ml-14 footer-icon"
            />
            <Instagram
              icon-color="#2AB4FF"
              width="23"
              heigth="29"
              class="ml-14 footer-icon"
            />
            <Twitter
              icon-color="#2AB4FF"
              width="24"
              heigth="24"
              class="ml-14 footer-icon"
            />
          </div>
        </v-col>
      </v-row>
    </v-container>
    <v-img
      src="@/assets/work/back.png"
      height="38px"
    >
      <div class="text-center mt-2 white--text">
        {{ $t("footer") }}
      </div>
    </v-img>
  </v-footer>
</template>

<script>
  import Logo from "@/components/icons/logo.vue";
  import Facebook from "@/components/icons/footer/facebook-footer.vue";
  import Instagram from "@/components/icons/footer/Instagram-footer.vue";
  import Telegram from "@/components/icons/footer/telegram-footer.vue";
  import Twitter from "@/components/icons/footer/twitter-footer.vue";

  export default {
    components: {
      Logo,
      Facebook,
      Instagram,
      Telegram,
      Twitter,
    },
    props: { color: { type: String } },
    methods: {
      async goTo (name) {
      await this.$router.push({ name })
      this.drawer = false
    }
    },
  };
</script>

<style scoped>
  .footer_container {
    max-width: 1100px;
    widows: 100%;
  }
  .footer-icon{
    cursor: pointer;
    opacity: 0.2;

  }
  .footer-icon:hover {
    opacity: 1;
  }
</style>
