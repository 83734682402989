<template>
  <!--  eslint-disable max-len  -->
 <svg :width="width" :height="height" viewBox="0 0 15 26" fill="none" xmlns="http://www.w3.org/2000/svg">
<path  d="M10 26H4V15H0V10H4V6C4 2.686 6.686 0 10 0H14V5H12C10.896 5 10 4.896 10 6V10H15L13 15H10V26Z" :fill="iconColor"/>
</svg>

</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 15
    },
    height: {
      type: [Number, String],
      default: 26
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    opacity:{
      type: Number || String,
      default: 1
    }
  }
}
</script>
