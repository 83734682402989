import Vue from "vue";
import Vuex from "vuex";
import { Projects, Categories, Vacancy } from "../api";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    projects: [],
    currentProject: {},
    categories: [],
    vacancies: [],
  },
  mutations: {
    setProjects(state, payload) {
      state.projects = [];
      state.projects = payload;
      if (payload.length) {
        state.currentProject = payload[0];
        state.currentProject.index = 0;
      }
    },
    setCurrentProject(state, { payload, index = 0 }) {
      state.currentProject = {};
      if (payload.length) {
        state.currentProject = payload[0];
        if (index >= 0) {
          state.currentProject.index = index;
        }
        return;
      }
      state.currentProject = payload;
      if (index >= 0) {
        state.currentProject.index = index;
      }
    },
    setCategories(state, payload) {
      state.categories = payload;
    },
    setVacancies(state, payload) {
      state.vacancies = payload;
    },
  },
  actions: {
    async getProjects({ commit, state }, filters) {
      try {
        const { data } = await Projects.getProjects(filters);
        commit("setProjects", data);
      } catch (e) {
        throw e;
      }
    },
    async getProjectBy({ commit }, project) {
      try {
        const { data } = await Projects.getProjects(project);
        commit("setCurrentProject", { payload: data });
      } catch (e) {
        throw e;
      }
    },
    async getCategories({ commit }) {
      try {
        const { data } = await Categories.getCategories();
        commit("setCategories", data);
      } catch (e) {
        throw e;
      }
    },
    async getVacancies({ commit }) {
      try {
        const { data } = await Vacancy.getVacancy();
        commit("setVacancies", data);
      } catch (e) {
        throw e;
      }
    },
  },
  modules: {},
});
