<template>
  <!--  eslint-disable max-len  -->
 <svg :width="width" :height="height" viewBox="0 0 27 23" fill="none" xmlns="http://www.w3.org/2000/svg">
<path  d="M6.32476 13.3266L9.59403 21.4629L13.8502 17.2256L21.1476 23L26.2857 0L0 10.9002L6.32476 13.3266ZM18.7745 6.59892L10.7289 13.9048L9.72679 17.6649L7.87557 13.0565L18.7745 6.59892Z" :fill="iconColor"/>
</svg>

</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
    opacity:{
      type: Number || String,
      default: 1
    }
  }
}
</script>
