import axios from "axios";
export default () => {
  return {
    getVacancy: async () => {
      try {
        const { data } = await axios.get("/vacancy");
        return data;
      } catch (e) {
        throw Error(e);
      }
    },
  };
};
