<template>
  <!--  eslint-disable max-len  -->
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 71 63"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M47.5135 18.2084C47.2276 16.7166 46.4676 15.3568 45.3465 14.3311C44.9616 13.9804 44.5382 13.6745 44.0844 13.4191C43.0276 12.8351 41.8383 12.5328 40.6308 12.541H29.575C29.5682 15.025 29.5616 17.5087 29.5553 19.9922C29.8258 19.8035 30.106 19.6291 30.3947 19.4696C32.1648 18.4977 33.935 18.2675 35.235 18.2549L41.6602 18.2305C41.1318 19.9455 40.1375 21.4803 38.7878 22.6641C37.4382 23.8479 35.7862 24.6342 34.0158 24.9355C32.2454 25.2368 30.4261 25.0412 28.7604 24.3705C27.0947 23.6998 25.6479 22.5803 24.5812 21.1368C23.5145 19.6932 22.8696 17.9821 22.7185 16.194C22.5673 14.4059 22.9157 12.611 23.725 11.009C24.5342 9.40708 25.7725 8.06098 27.302 7.12058C28.8315 6.18018 30.5922 5.68238 32.3881 5.68261C33.8317 5.68261 35.2737 6.1185 35.2737 6.1185C36.7343 6.5787 38.067 7.37393 39.1651 8.44063L43.1622 4.4372C41.423 2.71474 39.2985 1.43078 36.9642 0.691523C36.9642 0.691523 34.6813 0.000244141 32.3881 0.000244141C23.889 0.000244141 17 6.88466 17 15.3763C17 23.8679 23.889 30.7523 32.3881 30.7523C39.9169 30.7523 46.1819 25.349 47.5135 18.2115V18.2084Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M51.4774 19.2328L47.4866 23.2205C47.9149 24.0639 48.9578 26.3963 48.4766 29.3687C47.7264 34.0043 43.9099 36.2129 43.5755 36.3982C40.6567 38.0204 37.4714 37.828 35.2302 36.9436C33.9949 36.4422 32.8694 35.7046 31.9171 34.7721C31.2516 34.107 30.6859 33.3493 30.2376 32.5224C29.964 32.0162 29.7365 31.4865 29.5576 30.9397V40.2266C35.5852 44.7448 44.175 44.2655 49.6583 38.7873C54.9799 33.4675 55.5865 25.2234 51.4774 19.2328Z"
      fill="url(#paint1_linear)"
    />
    <path
      d="M51.4774 19.2328L47.4866 23.2205C47.9149 24.0639 48.9578 26.3963 48.4766 29.3687C47.7264 34.0043 43.9099 36.2129 43.5755 36.3982C40.6567 38.0204 37.4714 37.828 35.2302 36.9436C33.9949 36.4422 32.8694 35.7046 31.9171 34.7721C31.2516 34.107 30.6859 33.3493 30.2376 32.5224C29.964 32.0162 29.7365 31.4865 29.5576 30.9397V40.2266C35.5852 44.7448 44.175 44.2655 49.6583 38.7873C54.9799 33.4675 55.5865 25.2234 51.4774 19.2328Z"
      fill="url(#paint2_linear)"
    />
    <path
      d="M47.5135 18.2084C47.2276 16.7166 46.4676 15.3568 45.3465 14.3311C44.9616 13.9804 44.5382 13.6745 44.0844 13.4191C43.0276 12.8351 41.8383 12.5328 40.6308 12.541H29.575C29.5682 15.025 29.5616 17.5087 29.5553 19.9922C29.8258 19.8035 30.106 19.6291 30.3947 19.4696C32.1648 18.4977 33.935 18.2675 35.235 18.2549L41.6602 18.2305C41.1318 19.9455 40.1375 21.4803 38.7878 22.6641C37.4382 23.8479 35.7862 24.6342 34.0158 24.9355C32.2454 25.2368 30.4261 25.0412 28.7604 24.3705C27.0947 23.6998 25.6479 22.5803 24.5812 21.1368C23.5145 19.6932 22.8696 17.9821 22.7185 16.194C22.5673 14.4059 22.9157 12.611 23.725 11.009C24.5342 9.40708 25.7725 8.06098 27.302 7.12058C28.8315 6.18018 30.5922 5.68238 32.3881 5.68261C33.8317 5.68261 35.2737 6.1185 35.2737 6.1185C36.7343 6.5787 38.067 7.37393 39.1651 8.44063L43.1622 4.4372C41.423 2.71474 39.2985 1.43078 36.9642 0.691523C36.9642 0.691523 34.6813 0.000244141 32.3881 0.000244141C23.889 0.000244141 17 6.88466 17 15.3763C17 23.8679 23.889 30.7523 32.3881 30.7523C39.9169 30.7523 46.1819 25.349 47.5135 18.2115V18.2084Z"
      fill="url(#paint3_linear)"
    />
    <path
      d="M32.3931 30.7505C31.4404 30.7508 30.4897 30.663 29.5532 30.488V30.9365C29.9996 32.2925 30.7395 33.5334 31.7203 34.5712C32.7012 35.6089 33.8989 36.4179 35.2282 36.9405V30.4888C34.2932 30.6628 33.3442 30.7504 32.3931 30.7505Z"
      fill="url(#paint4_linear)"
    />
    <g opacity="0.68">
      <path
        opacity="0.68"
        d="M41.6254 17.2907C41.5321 16.5428 41.2578 15.8289 40.8262 15.2109C40.3946 14.5928 39.8187 14.0892 39.1484 13.7436C37.6085 12.8648 35.2175 12.6062 33.5239 12.5416C32.5055 12.5022 31.7387 12.5329 31.5628 12.5416H29.5749C29.5681 15.0256 29.5615 17.5093 29.5552 19.9927C29.8257 19.8038 30.1058 19.6291 30.3945 19.4693C32.1647 18.4975 33.9349 18.2673 35.2349 18.2547L41.6601 18.2302C41.6791 17.9167 41.6675 17.602 41.6254 17.2907Z"
        fill="url(#paint5_linear)"
      />
    </g>
    <path
      d="M30.3945 19.4693C30.1059 19.6278 29.8257 19.8012 29.5552 19.9888V24.6464C31.405 25.2087 33.3803 25.2087 35.2302 24.6464V18.2617H34.8957C33.6359 18.3146 32.0156 18.5826 30.3945 19.4693Z"
      fill="url(#paint6_linear)"
    />
    <path
      opacity="0.65"
      d="M36.9642 0.691523C36.9642 0.691523 34.6813 0.000244141 32.3881 0.000244141C23.889 0.000244141 17 6.88466 17 15.3763C17 23.8679 23.889 30.7523 32.3881 30.7523C35.8559 30.7572 39.2227 29.5861 41.9379 27.4307C34.8809 29.5455 27.8412 28.1141 24.0791 23.6472C23.4765 22.9315 20.7147 19.6516 21.1699 14.9995C21.2227 14.4643 21.8009 9.4338 26.1396 6.84682C28.5716 5.39648 31.5748 5.10326 34.4423 5.91435C34.9455 6.01839 35.2769 6.1185 35.2769 6.1185C36.7375 6.5787 38.0701 7.37393 39.1683 8.44063L43.1654 4.4372C41.4253 2.71432 39.2996 1.43033 36.9642 0.691523Z"
      fill="url(#paint7_linear)"
    />
    <path
      opacity="0.16"
      d="M34.3895 32.4263C34.6227 33.0559 34.9039 33.6667 35.2304 34.2534V30.4888C34.7866 30.5713 34.3362 30.6347 33.8792 30.6788C34.008 31.2725 34.1786 31.8565 34.3895 32.4263Z"
      fill="url(#paint8_linear)"
    />
    <path
      opacity="0.16"
      d="M33.6266 24.987C34.1699 24.9178 34.7064 24.8029 35.2303 24.6433V18.2586H34.8958C34.4732 18.2746 34.0518 18.3146 33.6337 18.3784H33.625L33.6266 24.987Z"
      fill="url(#paint9_linear)"
    />
    <path
      opacity="0.31"
      d="M35.2302 36.857C34.5115 36.4174 33.874 35.8575 33.3456 35.2017C32.104 33.652 31.7766 31.9218 31.715 30.7371C30.9902 30.7061 30.2684 30.6237 29.5552 30.4904V30.9389C30.0015 32.2949 30.7414 33.5358 31.7223 34.5736C32.7032 35.6113 33.9009 36.4202 35.2302 36.9429V36.857Z"
      fill="url(#paint10_linear)"
    />
    <path
      opacity="0.31"
      d="M31.7403 18.8663C30.9658 19.1444 30.2311 19.5228 29.5552 19.9919V24.6496C30.2661 24.8641 30.9992 24.9963 31.7403 25.0437V18.8663Z"
      fill="url(#paint11_linear)"
    />
    <path
      d="M20.564 21.3503C18.8388 17.3413 18.7662 11.0647 23.0158 7.20862C26.7155 3.8476 32.6587 3.31476 36.5217 5.80715C37.5742 6.49585 38.4751 7.39196 39.169 8.44063L43.1661 4.4372C41.4259 2.71421 39.2999 1.43022 36.9642 0.691523C36.9642 0.691523 34.6813 0.000244141 32.3881 0.000244141C23.889 0.000244141 17 6.88466 17 15.3763C17 23.8679 23.889 30.7523 32.3881 30.7523C34.5378 30.7553 36.664 30.3062 38.6287 29.4344C30.6155 30.9856 23.1514 27.3598 20.564 21.3503Z"
      fill="url(#paint12_linear)"
    />
    <path
      d="M40.5975 7.00896C41.4526 6.15136 42.3074 5.29403 43.162 4.43696C41.4229 2.7145 39.2983 1.43054 36.964 0.691279C36.964 0.691279 34.6811 0 32.3879 0C24.7408 0 18.3977 5.57359 17.2034 12.8773C18.5192 6.57385 23.6112 2.01157 29.3761 1.64819C35.9796 1.23201 40.2267 6.5305 40.5975 7.00896Z"
      fill="url(#paint13_linear)"
    />
    <path
      opacity="0.65"
      d="M32.3883 2.07925e-05C29.8564 -0.00414839 27.3629 0.618729 25.1309 1.81295C26.0128 1.38337 30.6528 -0.759046 35.9018 1.22178C39.0572 2.4128 40.9599 4.53945 41.8592 5.73993L43.1616 4.4354C41.4225 2.71362 39.2982 1.43022 36.9644 0.6913C36.9644 0.6913 34.6815 2.07925e-05 32.3883 2.07925e-05Z"
      fill="url(#paint14_linear)"
    />
    <g opacity="0.25">
      <path
        opacity="0.25"
        d="M45.3464 14.334C44.9615 13.9834 44.538 13.6774 44.0842 13.422C43.0278 12.837 41.8384 12.5335 40.6306 12.5408H29.5749C29.5681 15.0248 29.5615 17.5085 29.5552 19.9919C31.551 17.9118 33.4284 17.1046 34.7135 16.7476C36.7014 16.1958 38.0266 16.5056 42.8591 16.7255C44.5544 16.8043 46.0059 16.8382 47.1087 16.8548C46.7157 15.8943 46.1137 15.0332 45.3464 14.334Z"
        fill="url(#paint15_linear)"
      />
    </g>
    <path
      opacity="0.1"
      d="M45.3463 14.334C44.9614 13.9834 44.538 13.6774 44.0841 13.422C43.0277 12.837 41.8383 12.5335 40.6305 12.5408H29.5748C29.5701 14.359 29.5651 16.1777 29.5598 17.9969H29.5645C30.2064 17.3306 30.9388 16.7578 31.7402 16.2951C34.2645 14.844 36.7628 14.9449 39.407 15.0852C42.0063 15.2275 44.5704 15.7513 47.0171 16.6396C46.6246 15.7645 46.0558 14.9797 45.3463 14.334Z"
      fill="url(#paint16_linear)"
    />
    <path
      opacity="0.32"
      d="M40.6308 12.5408H29.575C29.5666 13.6517 29.5582 14.7625 29.5498 15.8734C30.2599 14.9701 31.167 14.2406 32.2019 13.7405C33.8364 12.9585 35.2926 13.0066 36.2101 13.0374C36.9319 13.0618 37.8359 13.1012 38.8646 13.5891C39.3029 13.7978 39.7121 14.0628 40.0817 14.3774L42.0539 12.674C41.585 12.5832 41.1084 12.5386 40.6308 12.5408Z"
      fill="url(#paint17_linear)"
    />
    <path
      d="M31.5635 12.5408H33.5246C32.5062 12.5014 31.7394 12.5329 31.5635 12.5408Z"
      fill="url(#paint18_linear)"
    />
    <path
      d="M46.6295 15.9002L46.6074 15.864C46.5972 15.8482 46.5861 15.8316 46.5767 15.8151C46.549 15.7709 46.5222 15.7276 46.493 15.6835C46.4796 15.6622 46.4654 15.6409 46.4512 15.6204C46.422 15.577 46.3936 15.5337 46.3629 15.4895C46.3471 15.4675 46.3297 15.4446 46.314 15.4218C46.284 15.38 46.254 15.3382 46.2225 15.2956C46.2035 15.2704 46.183 15.2452 46.1633 15.2168C46.1325 15.1774 46.1026 15.138 46.071 15.0986C46.0395 15.0592 46.0229 15.041 45.9984 15.0119C45.974 14.9827 45.9393 14.9401 45.9085 14.9047C45.8777 14.8692 45.8485 14.8385 45.8186 14.8054C45.7886 14.7723 45.7626 14.7431 45.7334 14.7123C45.7042 14.6816 45.6545 14.6335 45.6166 14.5925C45.593 14.5689 45.5709 14.5445 45.5464 14.5208C45.4817 14.457 45.4155 14.3939 45.3468 14.3316C44.9619 13.981 44.5385 13.6751 44.0847 13.4196C43.9995 13.3716 43.9135 13.3266 43.8259 13.2825L43.7597 13.2494C43.6808 13.2108 43.6019 13.1706 43.5183 13.1367L43.4946 13.1264C43.4031 13.0862 43.3108 13.0476 43.2169 13.0121L43.1704 12.9948C43.0821 12.9617 42.9937 12.9294 42.9038 12.9002L42.8872 12.8939C42.7862 12.8616 42.6853 12.8308 42.5835 12.8025H42.574C42.4723 12.7741 42.3705 12.7481 42.268 12.7236H42.264C42.0534 12.6756 41.8396 12.6369 41.625 12.6078C41.2969 12.5633 40.9662 12.5409 40.635 12.5408H33.5244C35.2172 12.6054 37.609 12.8639 39.1488 13.7428C39.9553 14.1714 40.6211 14.8231 41.0666 15.6199C41.512 16.4167 41.7182 17.3249 41.6605 18.2357C40.9752 20.4526 39.5169 22.3503 37.5505 23.5844C35.5841 24.8184 33.2401 25.3068 30.944 24.9611C28.6478 24.6153 26.552 23.4582 25.0369 21.6998C23.5219 19.9415 22.6882 17.6986 22.6871 15.3784C22.6878 14.9691 22.7142 14.5603 22.766 14.1543C22.624 15.2271 22.0892 20.3585 25.5002 23.8621C29.7544 28.2313 37.5025 27.3516 42.0463 23.6675C44.8972 21.3548 46.0923 18.2578 46.5948 15.9333L46.6295 15.9002Z"
      fill="url(#paint19_linear)"
    />
    <path
      d="M22.7732 14.0786C22.7732 14.0881 22.7732 14.0983 22.7732 14.1086L22.7779 14.0786H22.7732Z"
      fill="url(#paint20_linear)"
    />
    <path
      d="M43.7365 13.2423C44.8819 14.3056 45.2826 15.3548 45.4435 15.9712C46.1898 18.8246 44.3762 22.2959 40.8438 24.5274C42.7053 23.6403 44.3639 22.3792 45.7157 20.8227C46.4078 20.0187 47.0106 19.1421 47.5135 18.2082C47.3306 17.1377 46.8917 16.1272 46.2341 15.2627C45.5765 14.3981 44.7197 13.705 43.7365 13.2423Z"
      fill="url(#paint21_linear)"
    />
    <path
      d="M30.3945 19.4694C30.1059 19.6278 29.8257 19.8012 29.5552 19.9888V23.9142C29.7957 22.9581 30.2163 22.0566 30.7945 21.2579C32.3524 19.1123 34.5399 18.4439 35.2302 18.2626H34.8957C33.6359 18.3146 32.0156 18.5826 30.3945 19.4694Z"
      fill="url(#paint22_linear)"
    />
    <path
      opacity="0.69"
      d="M49.146 33.7024C47.1368 38.2639 41.2575 40.8351 35.5242 38.9473C36.5801 39.9847 37.8436 40.7873 39.2318 41.3025C42.3359 42.4471 45.0866 41.7558 46.0774 41.4547C47.3949 40.7443 48.6016 39.8457 49.6596 38.7873C54.9804 33.4675 55.587 25.2234 51.4779 19.2328L47.4871 23.2205C49.8986 26.3545 50.5983 30.4029 49.146 33.7024Z"
      fill="url(#paint23_linear)"
    />
    <path
      d="M46.0709 38.781C42.7081 40.5001 37.34 40.8257 33.3705 37.5758C31.0505 35.6762 30.0147 33.2129 29.5532 31.2778V40.2266C35.5808 44.7448 44.1706 44.2655 49.6539 38.7873C54.9802 33.4675 55.5868 25.2234 51.4777 19.2328C54.6323 27.5566 51.7302 35.889 46.0709 38.781Z"
      fill="url(#paint24_linear)"
    />
    <path
      d="M49.6558 38.7873C53.4108 35.0353 54.0363 30.0041 54.0994 29.3017C54.5775 24.0308 52.1746 20.2521 51.4773 19.2321L50.0203 20.6879C50.4255 21.1752 50.7841 21.6993 51.0915 22.2534C53.3666 26.3585 52.1218 30.9294 51.6753 32.2962C49.6101 38.6242 43.5501 41.0456 42.8078 41.3341C36.1626 43.8817 30.344 40.6775 29.5552 40.2266C35.5828 44.7448 44.1726 44.2655 49.6558 38.7873Z"
      fill="url(#paint25_linear)"
    />
    <path
      d="M49.6559 38.7873C49.9877 38.4558 50.3011 38.1142 50.5962 37.7626C45.0955 41.6194 38.6293 42.6024 33.7976 39.991C32.6751 39.384 31.0366 38.2569 29.5552 36.142V40.2298C35.5828 44.7448 44.1726 44.2655 49.6559 38.7873Z"
      fill="url(#paint26_linear)"
    />
    <path
      d="M17.0205 14.3254C17.2469 11.6533 18.199 9.0316 19.8067 6.86475C20.6086 5.77684 21.5665 4.81298 22.6497 4.00426C23.7365 3.19558 24.9437 2.56253 26.2272 2.12827C26.8695 1.91091 27.5293 1.74873 28.1993 1.6435C28.3665 1.6104 28.5361 1.60015 28.7042 1.57966L29.2106 1.52369L29.7186 1.50477C29.8882 1.50083 30.0586 1.48507 30.2282 1.4961L30.737 1.51423C30.8214 1.51896 30.9066 1.51423 30.991 1.52684L31.2443 1.55364L31.7507 1.60724C31.9195 1.62931 32.086 1.66321 32.2532 1.69237L32.5033 1.73809C32.5872 1.75137 32.6704 1.769 32.7525 1.7909L33.2464 1.91544L33.4925 1.9785L33.7346 2.05732L34.2174 2.20709C36.773 3.13927 38.9959 4.80518 40.6071 6.99559L40.5819 7.0153L40.2474 6.6401L40.0817 6.4525C40.0265 6.38944 39.9729 6.3256 39.9121 6.26806L39.5587 5.91335L39.382 5.736C39.3236 5.6761 39.2582 5.62328 39.1966 5.56732C38.9458 5.3474 38.7083 5.1133 38.4409 4.91309L38.0504 4.60252C37.9187 4.50005 37.7791 4.4102 37.6434 4.31324C36.561 3.5438 35.3724 2.93576 34.1149 2.50819L33.6416 2.35764L33.4049 2.28276L33.1635 2.22285L32.6815 2.10383C32.6027 2.08176 32.5238 2.06915 32.4386 2.05496L32.194 2.01161C32.0315 1.98481 31.8698 1.94855 31.7057 1.92648L31.2127 1.86973L30.9658 1.84135C30.8869 1.8311 30.8009 1.83189 30.7181 1.82716L30.2227 1.80509C30.057 1.7909 29.8922 1.80509 29.7265 1.80509L29.2303 1.81691L28.7357 1.86342C28.5708 1.88155 28.4052 1.88864 28.2419 1.9186C27.585 2.01158 26.9373 2.16085 26.3061 2.36473C25.0404 2.77756 23.8475 3.38669 22.7712 4.16979C21.6926 4.95494 20.7357 5.89462 19.9314 6.95855C18.3079 9.07653 17.3179 11.6659 17.0505 14.3277L17.0205 14.3254Z"
      fill="url(#paint27_linear)"
    />
    <path
      d="M28.2697 4.54181C27.895 4.64664 27.5242 4.76409 27.1613 4.90282C26.9783 4.96824 26.7992 5.04312 26.6194 5.11564L26.3527 5.2323L26.2194 5.29063L26.0885 5.35369C25.3888 5.68471 24.7216 6.08055 24.0958 6.53603C23.471 6.98827 22.8931 7.50187 22.3706 8.06914L21.9959 8.50819C21.9652 8.54445 21.9328 8.58071 21.9028 8.61775L21.8161 8.73362L21.6441 8.96458L21.4713 9.19553L21.3136 9.432L21.1558 9.6732L21.0114 9.92228C20.2426 11.2561 19.7694 12.7394 19.6239 14.2717C19.4783 15.803 19.5873 17.3478 19.9465 18.8435C20.1173 19.5928 20.3451 20.328 20.6281 21.0426C20.9201 21.7522 21.2757 22.4339 21.6906 23.0794C22.1056 23.728 22.5753 24.3398 23.0948 24.9081C24.1451 26.0414 25.3702 26.9994 26.7235 27.7458C27.3991 28.1218 28.1018 28.447 28.8258 28.7184C29.5508 28.9923 30.2945 29.2138 31.0511 29.3813C31.4298 29.4673 31.8124 29.5343 32.1958 29.5934C32.3874 29.6257 32.5807 29.6462 32.7732 29.6722C32.9657 29.6982 33.1605 29.7124 33.3538 29.7321C33.1597 29.7211 32.9649 29.7124 32.7708 29.699C32.5768 29.6856 32.3827 29.6691 32.1894 29.6462C31.8021 29.6013 31.4156 29.5461 31.033 29.4728C30.2645 29.3294 29.5072 29.1319 28.7666 28.8816C27.2806 28.3717 25.8837 27.6323 24.6267 26.6903C23.9985 26.2178 23.4111 25.6934 22.8708 25.1225C22.3323 24.5479 21.844 23.9282 21.4114 23.2702C20.9796 22.6095 20.6099 21.9102 20.307 21.1814C20.0151 20.4508 19.7793 19.699 19.6018 18.9326C19.2381 17.3973 19.1331 15.8123 19.291 14.2426C19.4564 12.6686 19.9639 11.1498 20.7779 9.79222L20.931 9.5392L21.0966 9.29484L21.2631 9.05128L21.4437 8.81481L21.6244 8.57834L21.7151 8.46168C21.7459 8.42385 21.7798 8.38838 21.8121 8.35133L22.2066 7.9115C22.7523 7.34674 23.3538 6.83863 24.002 6.39494C24.6489 5.95617 25.3355 5.57906 26.053 5.26856C26.7673 4.95692 27.5095 4.71359 28.2697 4.54181Z"
      fill="#0098FF"
    />
    <path
      d="M43.7561 13.2518C44.3273 13.7364 44.7894 14.3362 45.1121 15.0119C45.1547 15.0962 45.191 15.1829 45.2289 15.2689C45.2667 15.3548 45.2991 15.4438 45.3291 15.5329C45.3906 15.7111 45.4432 15.8918 45.4868 16.0752C45.5729 16.443 45.6216 16.8185 45.632 17.1961C45.6397 17.5729 45.6133 17.9496 45.5531 18.3217C45.4891 18.6913 45.3949 19.0551 45.2715 19.4094C45.0278 20.1138 44.6696 20.7732 44.2113 21.3611C44.5697 20.7221 44.8547 20.0446 45.0609 19.3416C45.2669 18.6477 45.3648 17.9261 45.3512 17.2024C45.3351 16.4808 45.1858 15.7684 44.911 15.101C44.6268 14.4272 44.2369 13.803 43.7561 13.2518Z"
      fill="#BDE6FF"
    />
    <g opacity="0.9">
      <path
        opacity="0.9"
        d="M50.02 20.688C50.5638 21.2826 51.0283 21.9449 51.4021 22.6585C51.496 22.8375 51.5851 23.0188 51.6671 23.2032C51.7492 23.3877 51.8249 23.576 51.8975 23.7644C52.038 24.1438 52.1568 24.531 52.2533 24.9239L52.3227 25.2195L52.3779 25.5182C52.4181 25.7161 52.4418 25.9171 52.4702 26.1173C52.5176 26.5186 52.5418 26.9223 52.5428 27.3264C52.5474 28.1321 52.4558 28.9355 52.2698 29.7195C52.3626 28.927 52.3921 28.1284 52.3582 27.3312C52.3228 26.5408 52.2108 25.7557 52.0237 24.987C51.8381 24.2155 51.5737 23.4651 51.2348 22.7476C50.8928 22.0259 50.4862 21.3366 50.02 20.688Z"
        fill="#EBE1FF"
      />
    </g>
    <g opacity="0.9">
      <path
        opacity="0.9"
        d="M29.5552 31.2794C29.8181 32.3757 30.2308 33.4306 30.7818 34.4143C31.0554 34.9037 31.3669 35.371 31.7135 35.8118C31.8854 36.0325 32.0708 36.2422 32.2586 36.4487C32.4463 36.6552 32.6475 36.8499 32.8573 37.0343C33.688 37.7815 34.6483 38.3708 35.6908 38.7732C36.7322 39.1763 37.8342 39.4012 38.9504 39.4384C41.1978 39.5174 43.4201 38.9452 45.3495 37.791C46.3152 37.2219 47.1751 36.4902 47.8912 35.6281C48.2485 35.1947 48.5639 34.7283 48.8331 34.2353C49.1035 33.7388 49.3335 33.2214 49.521 32.688C49.2055 33.7804 48.691 34.8052 48.0032 35.7109C47.301 36.6125 46.4425 37.3808 45.4686 37.9794C43.5156 39.197 41.243 39.8039 38.9425 39.7222C37.7952 39.6801 36.6633 39.4434 35.5954 39.0223C35.063 38.8106 34.5514 38.5499 34.0674 38.2435C33.5823 37.9396 33.1281 37.589 32.7114 37.1967C31.8777 36.4147 31.1841 35.4961 30.6604 34.4805C30.4002 33.9761 30.1767 33.4537 29.9914 32.9174C29.8983 32.6504 29.815 32.3801 29.7413 32.1063C29.6696 31.8328 29.6065 31.5569 29.5552 31.2794Z"
        fill="#EBE1FF"
      />
    </g>
    <path
      d="M7.00173 58.2317V61.5757C6.62059 61.7946 6.20745 61.9524 5.77738 62.0435C5.23075 62.1689 4.67134 62.2303 4.11048 62.2262C3.2144 62.2262 2.45852 62.0307 1.84284 61.6396C1.24064 61.2624 0.759755 60.7201 0.457568 60.0773C0.147473 59.4134 -0.0087992 58.6883 0.000382386 57.9558C-0.00776886 57.2246 0.156695 56.5019 0.480428 55.8461C0.799934 55.2065 1.29871 54.6735 1.91599 54.3121C2.55605 53.9283 3.33571 53.7365 4.25495 53.7365C4.76672 53.7331 5.27503 53.8204 5.75635 53.9941C6.19975 54.1515 6.60594 54.3984 6.94961 54.7196L6.49242 55.4505C6.21351 55.1725 5.87794 54.9578 5.50856 54.821C5.1068 54.6654 4.67943 54.5864 4.24855 54.588C3.57801 54.588 3.00623 54.7357 2.53319 55.0311C2.06559 55.3223 1.69267 55.7428 1.45972 56.2417C1.20953 56.7823 1.08451 57.3722 1.09397 57.9676C1.08447 58.5709 1.20484 59.1691 1.44692 59.7219C1.66484 60.2159 2.02114 60.6363 2.47284 60.9325C2.92454 61.2279 3.47042 61.3756 4.11048 61.3756C4.72656 61.3832 5.34055 61.3026 5.93374 61.1362V58.2317H7.00173Z"
      :fill="color"
    />
    <path
      d="M12.9998 58.4592H11.5121V62.0434H10.4084V53.8927H13.1442C14.0714 53.8927 14.7752 54.0925 15.2555 54.4921C15.7359 54.8916 15.9757 55.4398 15.9751 56.1366C15.9751 56.7196 15.8191 57.1971 15.507 57.5693C15.1949 57.9414 14.7349 58.1872 14.1272 58.3066L16.7908 62.0462H15.5106L12.9998 58.4592ZM11.5121 57.6204H13.1442C14.2957 57.6204 14.8715 57.1368 14.8715 56.1695C14.8715 55.7145 14.7234 55.3591 14.4271 55.1033C14.1309 54.8475 13.7041 54.7196 13.147 54.7196H11.5121V57.6204Z"
      :fill="color"
    />
    <path
      d="M24.7755 61.2166V62.0388H19.7766V53.8927H24.5396V54.715H20.8821V57.3162H24.0614V58.1385H20.8803V61.2074L24.7755 61.2166Z"
      :fill="color"
    />
    <path
      d="M32.1919 59.5144H28.9175L27.994 62.0434H26.8904L30.0568 53.7831H31.0764L34.2556 62.0416H33.1282L32.1919 59.5144ZM31.892 58.6867L30.5607 55.0668L29.2293 58.6867H31.892Z"
      :fill="color"
    />
    <path
      d="M42.6887 54.7314H39.941V62.0407H38.8374V54.7314H36.0942V53.8927H42.6923L42.6887 54.7314Z"
      :fill="color"
    />
    <path
      d="M50.6517 54.4226C51.2345 54.7608 51.7024 55.2659 51.9949 55.8726C52.3027 56.5243 52.4566 57.2379 52.4447 57.9585C52.4593 58.6907 52.3054 59.4165 51.9949 60.08C51.7033 60.6852 51.2349 61.1878 50.6517 61.5217C50.0555 61.8689 49.3338 62.0428 48.4864 62.0434H45.6272V53.8927H48.4819C49.3292 53.8927 50.0524 54.0693 50.6517 54.4226ZM50.5977 60.3321C51.0933 59.7486 51.3411 58.9614 51.3411 57.9703C51.3411 56.9793 51.0896 56.1927 50.5867 55.6104C50.0875 55.0232 49.3865 54.7296 48.4837 54.7296H46.7308V61.2019H48.4819C49.3938 61.2032 50.0976 60.9123 50.5931 60.3294L50.5977 60.3321Z"
      :fill="color"
    />
    <path
      d="M60.6454 61.2166V62.0388H55.6438V53.8927H60.4058V54.715H56.7484V57.3162H59.9267V58.1385H56.7474V61.2074L60.6454 61.2166Z"
      :fill="color"
    />
    <path
      d="M70.254 53.8927L67.0985 62.1394H66.0314L62.864 53.8927H63.9923L66.5709 60.9041L69.1503 53.8927H70.254Z"
      :fill="color"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="17"
        y1="15.3763"
        x2="47.5135"
        y2="15.3763"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#15327A" />
        <stop
          offset="0.48"
          stop-color="#233F9B"
        />
        <stop
          offset="0.99"
          stop-color="#2475EA"
        />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="29.5553"
        y1="31.2613"
        x2="54.1626"
        y2="31.2613"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.01"
          stop-color="#33007C"
        />
        <stop
          offset="0.17"
          stop-color="#886CFF"
        />
        <stop
          offset="1"
          stop-color="#6120A0"
        />
      </linearGradient>
      <linearGradient
        id="paint2_linear"
        x1="27.3638"
        y1="40.1777"
        x2="61.059"
        y2="24.706"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.01"
          stop-color="#2D007C"
        />
        <stop
          offset="0.46"
          stop-color="#9F8DFF"
        />
        <stop
          offset="1"
          stop-color="#2D007C"
        />
      </linearGradient>
      <linearGradient
        id="paint3_linear"
        x1="16.0968"
        y1="23.1096"
        x2="43.6512"
        y2="10.009"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358AEF" />
        <stop
          offset="0.28"
          stop-color="#15327A"
        />
        <stop
          offset="0.65"
          stop-color="#00C0FF"
        />
        <stop
          offset="1"
          stop-color="#091044"
        />
      </linearGradient>
      <linearGradient
        id="paint4_linear"
        x1="18.7184"
        y1="34.244"
        x2="35.1769"
        y2="33.6053"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.01"
          stop-color="#240A5B"
        />
        <stop
          offset="0.56"
          stop-color="#391589"
        />
        <stop
          offset="1"
          stop-color="#451299"
        />
      </linearGradient>
      <linearGradient
        id="paint5_linear"
        x1="42.2919"
        y1="12.2964"
        x2="34.5381"
        y2="15.8923"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.01"
          stop-color="#240A5B"
        />
        <stop
          offset="0.56"
          stop-color="#391589"
        />
        <stop
          offset="1"
          stop-color="#5C1C99"
        />
      </linearGradient>
      <linearGradient
        id="paint6_linear"
        x1="1141.68"
        y1="1883.48"
        x2="2126.7"
        y2="1887.74"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.01"
          stop-color="#240A5B"
        />
        <stop
          offset="0.56"
          stop-color="#391589"
        />
        <stop
          offset="1"
          stop-color="#451299"
        />
      </linearGradient>
      <linearGradient
        id="paint7_linear"
        x1="5.36526"
        y1="15.3763"
        x2="28.5495"
        y2="15.3763"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.53"
          stop-color="#07113F"
        />
        <stop
          offset="1"
          stop-color="#0A36A3"
        />
      </linearGradient>
      <linearGradient
        id="paint8_linear"
        x1="31.6088"
        y1="32.3711"
        x2="39.4744"
        y2="32.3711"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#868DFA" />
        <stop
          offset="0.55"
          stop-color="#B194FF"
        />
        <stop
          offset="0.99"
          stop-color="#AEA5FF"
        />
      </linearGradient>
      <linearGradient
        id="paint9_linear"
        x1="570.007"
        y1="1864.38"
        x2="678.959"
        y2="1864.38"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#868DFA" />
        <stop
          offset="0.55"
          stop-color="#B194FF"
        />
        <stop
          offset="0.99"
          stop-color="#AEA5FF"
        />
      </linearGradient>
      <linearGradient
        id="paint10_linear"
        x1="1765.54"
        y1="2790.34"
        x2="2173.79"
        y2="2790.34"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.53"
          stop-color="#07113F"
        />
        <stop
          offset="1"
          stop-color="#0A36A3"
        />
      </linearGradient>
      <linearGradient
        id="paint11_linear"
        x1="715.658"
        y1="1739.63"
        x2="821.505"
        y2="1739.63"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.53"
          stop-color="#07113F"
        />
        <stop
          offset="1"
          stop-color="#0A36A3"
        />
      </linearGradient>
      <linearGradient
        id="paint12_linear"
        x1="34.35"
        y1="31.4586"
        x2="28.2692"
        y2="1.83573"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#2AAEFA" />
        <stop
          offset="0.48"
          stop-color="#233F9B"
        />
        <stop
          offset="0.7"
          stop-color="#2670C5"
        />
        <stop
          offset="0.99"
          stop-color="#2AB4FF"
        />
      </linearGradient>
      <linearGradient
        id="paint13_linear"
        x1="17.3335"
        y1="8.08568"
        x2="48.1171"
        y2="0.323573"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#358AEF" />
        <stop
          offset="0.28"
          stop-color="#15327A"
        />
        <stop
          offset="0.65"
          stop-color="#00A9EF"
        />
        <stop
          offset="1"
          stop-color="#091044"
        />
      </linearGradient>
      <linearGradient
        id="paint14_linear"
        x1="3815.19"
        y1="-161.981"
        x2="5895.67"
        y2="2342.53"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.53"
          stop-color="#07113F"
        />
        <stop
          offset="1"
          stop-color="#0A36A3"
        />
      </linearGradient>
      <linearGradient
        id="paint15_linear"
        x1="43.8996"
        y1="12.8884"
        x2="31.2736"
        y2="17.0056"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#0F41FD" />
        <stop
          offset="1"
          stop-color="#8E3FFF"
        />
      </linearGradient>
      <linearGradient
        id="paint16_linear"
        x1="41.5945"
        y1="13.921"
        x2="24.2322"
        y2="17.4576"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#07113F" />
        <stop
          offset="1"
          stop-color="#6B20A0"
        />
      </linearGradient>
      <linearGradient
        id="paint17_linear"
        x1="29.5498"
        y1="14.2071"
        x2="34.4186"
        y2="14.2071"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.01"
          stop-color="#240A5B"
        />
        <stop
          offset="0.56"
          stop-color="#391589"
        />
        <stop
          offset="1"
          stop-color="#172784"
        />
      </linearGradient>
      <linearGradient
        id="paint18_linear"
        x1="17.2806"
        y1="19.1966"
        x2="37.9196"
        y2="10.1824"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#15327A" />
        <stop
          offset="0.48"
          stop-color="#233F9B"
        />
        <stop
          offset="0.99"
          stop-color="#2AB4FF"
        />
      </linearGradient>
      <linearGradient
        id="paint19_linear"
        x1="4359.01"
        y1="4482.66"
        x2="9214.02"
        y2="918.922"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#15327A" />
        <stop
          offset="0.48"
          stop-color="#233F9B"
        />
        <stop
          offset="0.99"
          stop-color="#2AB4FF"
        />
      </linearGradient>
      <linearGradient
        id="paint20_linear"
        x1="23.4241"
        y1="20.509"
        x2="24.8898"
        y2="20.4081"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#15327A" />
        <stop
          offset="0.48"
          stop-color="#233F9B"
        />
        <stop
          offset="0.99"
          stop-color="#2AB4FF"
        />
      </linearGradient>
      <linearGradient
        id="paint21_linear"
        x1="37.2419"
        y1="27.7316"
        x2="46.8258"
        y2="14.0857"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#15327A" />
        <stop
          offset="0.99"
          stop-color="#2475EA"
        />
      </linearGradient>
      <linearGradient
        id="paint22_linear"
        x1="29.5552"
        y1="21.0915"
        x2="35.2302"
        y2="21.0915"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.01"
          stop-color="#240A5B"
        />
        <stop
          offset="0.56"
          stop-color="#391589"
        />
        <stop
          offset="1"
          stop-color="#4B1C99"
        />
      </linearGradient>
      <linearGradient
        id="paint23_linear"
        x1="32.891"
        y1="38.4775"
        x2="60.7961"
        y2="24.166"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.01"
          stop-color="#6142B7"
        />
        <stop
          offset="0.46"
          stop-color="#D4D1FF"
        />
        <stop
          offset="0.62"
          stop-color="#BDB3FF"
        />
        <stop
          offset="1"
          stop-color="#4820A0"
        />
      </linearGradient>
      <linearGradient
        id="paint24_linear"
        x1="29.5556"
        y1="31.2613"
        x2="54.163"
        y2="31.2613"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.01"
          stop-color="#5A42B7"
        />
        <stop
          offset="0.23"
          stop-color="#A68DFF"
        />
        <stop
          offset="1"
          stop-color="#4C20A0"
        />
      </linearGradient>
      <linearGradient
        id="paint25_linear"
        x1="41.4187"
        y1="37.8336"
        x2="44.6811"
        y2="13.4623"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.01"
          stop-color="#2F0A7A"
          stop-opacity="0.3"
        />
        <stop
          offset="0.26"
          stop-color="#2F1589"
          stop-opacity="0.5"
        />
        <stop
          offset="0.47"
          stop-color="#4820A0"
        />
      </linearGradient>
      <linearGradient
        id="paint26_linear"
        x1="21.2549"
        y1="39.7143"
        x2="41.8447"
        y2="39.7143"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          offset="0.02"
          stop-color="#21007C"
          stop-opacity="0.8"
        />
        <stop
          offset="0.11"
          stop-color="#401795"
        />
        <stop
          offset="0.68"
          stop-color="#5420A0"
        />
      </linearGradient>
      <linearGradient
        id="paint27_linear"
        x1="16.8785"
        y1="7.90837"
        x2="40.7215"
        y2="7.90837"
        gradientUnits="userSpaceOnUse"
      >
        <stop
          stop-color="#BDE6FF"
          stop-opacity="0"
        />
        <stop
          offset="0.17"
          stop-color="#BDE6FF"
        />
        <stop
          offset="1"
          stop-color="#BDE6FF"
        />
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: [String],
      default: 'white'
    }

  }
}
</script>
