import axios from "axios";
export default () => {
  return {
    sendMail: async (params) => {
      try {
        const formData = new FormData();
        for (let key in params) {
          if (params[key]) {
            formData.append(key, params[key]);
          }
        }
        const { data } = await axios({
          method: "post",
          url: "/contact",
          data: formData,
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        });

        return data;
      } catch (e) {
        throw Error(e);
      }
    },
    sendMailForSubscription: async (email) => {
      try {
        const { data } = await axios({
          method: "post",
          url: "/subscribe",
          data: { email },
        });

        return data;
      } catch (e) {
        throw Error(e);
      }
    },
  };
};
