import Vue from "vue";
import VueSlickCarousel from "vue-slick-carousel";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import store from "./store";

Vue.component("VueSlickCarousel", VueSlickCarousel);
Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  i18n,
  store,
  render: h => h(App)
}).$mount("#app");
