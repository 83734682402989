import axios from "axios";
import _Projects from "./projects";
import _Categories from "./categories";
import _Other from "./other";
import _Vacancy from "./vacancies";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
// axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

export const Projects = _Projects();
export const Categories = _Categories();
export const Other = _Other();
export const Vacancy = _Vacancy();
