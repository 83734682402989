import axios from "axios";
export default () => {
  return {
    getProjects: async filters => {
      try {
        if (filters?.category === "all") {
          filters.category = null;
        }
        const { data } = await axios.get("/project", { params: filters });
        return data;
      } catch (e) {
        throw Error(e);
      }
    }
    // getProjectByParams: async (params) => {
    //   try {
    //     const { data } = await axios.get("/project", { params });
    //     return data;
    //   } catch (e) {
    //     throw Error(e);
    //   }
    // },
  };
};
