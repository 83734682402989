import axios from "axios";
export default () => {
  return {
    getCategories: async () => {
      try {
        const { data } = await axios.get("/category");
        return data;
      } catch (e) {
        throw Error(e);
      }
    }
  };
};
