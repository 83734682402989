import Vue from "vue";
import VueI18n from "vue-i18n";
import en from "@/locales/en.json";
import ru from "@/locales/ru.json";
import ua from "@/locales/ua.json";

Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: "en",
  messages: {
    en,
    ru,
    ua
  }
});
export default i18n;
