<template>
  <!--  eslint-disable max-len  -->
 <svg :width="width" :height="height" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5.62413 0H18.3722C22.513 0 23.9963 1.48327 23.9963 5.62413V18.3722C23.9963 22.513 22.513 23.9963 18.3722 23.9963H5.62413C1.48327 23.9963 0 22.513 0 18.3722V5.62413C0 1.48327 1.48327 0 5.62413 0ZM11.9982 5.99906C8.68517 5.99906 5.99908 8.68514 5.99908 11.9981C5.99908 15.3111 8.68517 17.9972 11.9982 17.9972C15.3111 17.9972 17.9972 15.3111 17.9972 11.9981C17.9972 8.68514 15.3111 5.99906 11.9982 5.99906ZM11.9982 15.7476C9.93147 15.7476 8.24873 14.0648 8.24873 11.9981C8.24873 9.92995 9.93147 8.24871 11.9982 8.24871C14.0648 8.24871 15.7476 9.92995 15.7476 11.9981C15.7476 14.0648 14.0648 15.7476 11.9982 15.7476ZM19.2466 5.54917C19.2466 5.99066 18.8887 6.34855 18.4472 6.34855C18.0057 6.34855 17.6478 5.99066 17.6478 5.54917C17.6478 5.10769 18.0057 4.7498 18.4472 4.7498C18.8887 4.7498 19.2466 5.10769 19.2466 5.54917Z" :fill="iconColor"/>
</svg>

</template>
<script>
export default {
  props: {
    iconName: {
      type: String,
      default: 'box'
    },
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    iconColor: {
      type: String,
      default: 'currentColor'
    },
      opacity:{
      type: Number || String,
      default: 1
    }
  }
}
</script>
